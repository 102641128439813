import { Content } from "@prismicio/client";
import cn from "clsx";
import { PrismicRichText, SliceComponentProps } from "@prismicio/react";
import { Link } from "@components/ui";
import { URL_PAGE_MEN, URL_PAGE_WOMEN } from "@middleware/constants";
import style from "./BarreProgramme.module.scss";

export type BarreProgrammeProps =
  SliceComponentProps<Content.BarreProgrammeSlice>;

const BarreProgramme = ({ slice }: BarreProgrammeProps): JSX.Element => {
  return (
    <section
      data-slice-type={slice.slice_type}
      data-slice-variation={slice.variation}
      className={style.root}
    >
      <div className="lk-wrap">
        <div className="lk-wrap-content">
          <div className={style.title}>
            <PrismicRichText field={slice.primary.title} />
          </div>
          <div className={cn("lk-flex space-center", style.action)}>
            <Link href={URL_PAGE_WOMEN} className="primary">
              {slice.primary.buttonWomen}
            </Link>
            <Link href={URL_PAGE_MEN} className="primary">
              {slice.primary.buttonMen}
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BarreProgramme;
